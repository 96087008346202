import React, { Component } from 'react';
import { Jumbotron, Image, Button } from 'react-bootstrap';
import Media from 'react-media';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './AwardsYear.css';
import Award from './Award';

const years = {
    2024: require("./2024.json"),
    2023: require("./2023.json"),
    2022: require("./2022.json"),
    2021: require("./2021.json"),
    2020: require("./2020.json"),
    2019: require("./2019.json"),
    2018: require("./2018.json"),
    2017: require("./2017.json"),
    2016: require("./2016.json"),
    2015: require("./2015.json"),
    2014: require("./2014.json"),
    "lifetime-achievement": require('./Lifetime-Achievement.json'),
    "hall-of-fame": require('./hall-of-fame.json')
}

export default class AwardsYear extends Component {
    constructor(props) {
        super(props);

        if (years[props.year] == null) {
            this.state = {
                year: props.year
            }
        } else {
            this.state = {
                year: props.year,
                awards: years[props.year].categories.map(entry => {
                    return <Award key={entry} entry={entry} name={entry.name} showart={years[props.year].showart} />
                })
            }
        }

        this.episode = this.episode.bind(this);
    }

    episode() {
        if (years[this.props.year] != null && years[this.props.year].episode) {
            return <Media query="(prefers-color-scheme: dark)">
                {matches =>
                    matches ? (
                        <Button variant="outline-light" href={years[this.props.year].episode}>Listen to Upgrade {years[this.props.year].episodeNum} <FontAwesomeIcon icon={faArrowRight} /></Button>
                    ) : (
                        <Button variant = "outline-dark" href = {years[this.props.year].episode}>Listen to Upgrade {years[this.props.year].episodeNum} <FontAwesomeIcon icon={faArrowRight} /></Button>
                    )
                }
            </Media>
        } else {
            return null;
        }
        

    }

    render() {
        let returning;

        let image;
        if (years[this.props.year] != null && years[this.props.year].image != null) {
            image = (
                <Media query="(prefers-color-scheme: dark)">
                    {matches =>
                        matches ? (
                            <Image className={`WinnerBadge${this.props.year == 'hall-of-fame' ? ' NoTopPadding' : ''}`} src={years[this.props.year].darkModeImage} />
                        ) : (
                                <Image className="WinnerBadge" src={years[this.props.year].image} />
                            )
                    }
                </Media>
            )
        }
        if(this.state.awards != null) {
            let yearName = this.props.year;
            if(this.props.year === "lifetime-achievement") {
                yearName = "Lifetime Achievement"
            }
            if (this.props.year === "hall-of-fame") {
                yearName = "Hall of Fame"
            }
            let jumbo = [(<Jumbotron className={`YearHeader${this.props.year == 'hall-of-fame' ? ' NoTopPadding' : ''}`}>
                {image}
                <h1>{yearName} Upgradies Winners</h1>
                {this.episode()}
            </Jumbotron>)];
            returning = jumbo.concat(this.state.awards);
        } else {
            let jumbo = [(<Jumbotron className="YearHeader">
                <h2>No Winners found for {this.props.year}</h2>
            </Jumbotron>)];
            returning = jumbo;
        }

        return returning;
    }
}
