import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Container, Jumbotron, Col, Row } from 'react-bootstrap';
import './App.css';
import SortByYear from './SortByYear';
import SortByCategory from './SortByCategory';
import Landing from './Landing';


class App extends Component {

  render() {
    return (
      <Router>
        <div className="App">
          <Navbar className="Navbar-Title" bg="dark" variant="dark" expand="md">
            <Container className='Navbar-Title-Main'>
              <LinkContainer to={`/`}>
                <Navbar.Brand className="Navbar-Title-Brand mx-auto" href="#">
                  <img
                    src="/img/medal.png"
                    className="Navbar-Title-Img d-inline-block align-center"
                    alt="Upgradies medal"
                  />
                  <span className='NavbarTitleBrand NavbarTitleText align-middle'>upgradies.com</span>
                  
                </Navbar.Brand>
              </LinkContainer>
            </Container>
          </Navbar>

          <Switch>
            <Route path="/year" component={SortByYear} />
            <Route path="/category" component={SortByCategory} />
            <Route path="/" component={Landing} />
          </Switch>

          <Jumbotron className="Footer justify-content-center">
            <Container>
              <Row>
                <Col xs={12}>Upgrade and the Upgradies Copyright © 2014-2025 <a href="https://relay.fm/">Relay</a>.</Col>
                <Col md={6} lg={4}>upgradies.com Developed by <a href="https://zmknox.com">zmknox</a></Col>
                <Col md={6} lg={4}>Artwork by JD Davis</Col>
                <Col md={6} lg={4}>Music by Chris Breen</Col>
                <Col md={6} lg={4}>Audio Editing by Jim Metzendorf</Col>
                <Col md={6} lg={4}>Video Editing by Chip Sudderth</Col>
                <Col md={6} lg={4}>Social Media by Jamie Snell</Col>
              </Row>       
            </Container>
          </Jumbotron>
        </div>
      </Router>
    );
  }
}

export default App;
