import React, { Component } from 'react';
import { Container, Button, Jumbotron, Row, Col, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Media from 'react-media';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Confetti from 'react-confetti';
import { SizeMe } from 'react-sizeme';
import './Landing.css';

export default class Landing extends Component {

    doConfetti(size) {
        const params = new URLSearchParams(this.props.location.search);

        if (params.get("confetti") === "true") {
            return (
                <Confetti width={size.width} height={1000} />
            )
        }
    }

    render() {
        return (
            <SizeMe render={({ size }) =>
                <div class="Landing">
                    {this.doConfetti(size)}
                    <Image className="Hero" src="/img/2024-banner.png" />

                    <Jumbotron className="WelcomeBox">
                        <Container>
                            <h1 className="WelcomeHeader">Welcome to upgradies.com!</h1>
                            <h6 className="WelcomeHelperText">
                                Here, you can view every award winner, as well as runners up,
                            of <a href="https://twitter.com/imyke">Myke Hurley</a> and <a href="https://twitter.com/jsnell">Jason Snell</a>'s
                                annual Upgradies. To get started, select how you would like to view
                                the awards:
                        </h6>
                            <Container className="text-center">
                                    <Media query="(prefers-color-scheme: dark)">
                                        {matches =>
                                            matches ? (
                                                <LinkContainer to={`/year/2024`}>
                                                    <Button variant="outline-light" className="WelcomeButton btn-lg">
                                                        View By Year
                                                    </Button>
                                                </LinkContainer>
                                            ) : (
                                                <LinkContainer to={`/year/2024`}>
                                                    <Button variant="outline-dark" className="WelcomeButton btn-lg">
                                                        View By Year
                                                    </Button>
                                                </LinkContainer>
                                            )
                                        }
                                    </Media>
                                    <Media query="(prefers-color-scheme: dark)">
                                        {matches =>
                                            matches ? (
                                                <LinkContainer to={`/category/best-ios-app`}>
                                                    <Button variant="outline-light" className="WelcomeButton btn-lg">
                                                        View By Category
                                                    </Button>
                                                </LinkContainer>
                                            ) : (
                                                <LinkContainer to={`/category/best-ios-app`}>
                                                    <Button variant="outline-dark" className="WelcomeButton btn-lg">
                                                        View By Category
                                                    </Button>
                                                </LinkContainer>
                                            )
                                        }
                                    </Media>
                            </Container>
                        </Container>
                    </Jumbotron>

                    <Jumbotron className="ListenToUpgrade mx-auto">
                        <Container>
                            <Row>
                                <Col md={6}>
                                    <Container className="CurrentYear">
                                        <Row>
                                            <Image className="LandingArtwork" href="https://relay.fm/upgrade/544" src="/img/showart/2024.png" />
                                        </Row>
                                        <Row>
                                            <div className="mx-auto text-center">
                                                <h2 className="ListenToHeading">Listen to the 2024 Upgradies</h2>
                                                <Media query="(prefers-color-scheme: dark)">
                                                    {matches =>
                                                        matches ? (
                                                            <Button variant="outline-light" className="LandingButton" href="https://relay.fm/upgrade/544">
                                                                The 2024 Upgradies <FontAwesomeIcon icon={faArrowRight} />
                                                            </Button>
                                                        ) : (
                                                            <Button variant="outline-dark" className="LandingButton" href="https://relay.fm/upgrade/544">
                                                                The 2024 Upgradies <FontAwesomeIcon icon={faArrowRight} />
                                                            </Button>
                                                        )
                                                    }
                                                </Media>

                                            </div>
                                        </Row>
                                    </Container>
                                </Col>
                                <Col md={6}>
                                    <Container>
                                        <Row>
                                            <Image className="LandingArtwork" href="https://relay.fm/upgrade" src="/img/upgrade-artwork-2024.jpg" />
                                        </Row>
                                        <Row>
                                            <div className="mx-auto text-center">
                                                <h3 className="ListenToHeading">Listen to Upgrade on Relay</h3>
                                                <Media query="(prefers-color-scheme: dark)">
                                                    {matches =>
                                                        matches ? (
                                                            <Button variant="outline-light" className="LandingButton" href="https://relay.fm/upgrade">
                                                                See All Episodes <FontAwesomeIcon icon={faArrowRight} />
                                                            </Button>
                                                        ) : (
                                                            <Button variant="outline-dark" className="LandingButton" href="https://relay.fm/upgrade">
                                                                See All Episodes <FontAwesomeIcon icon={faArrowRight} />
                                                            </Button>
                                                        )
                                                    }
                                                </Media>

                                            </div>
                                        </Row>
                                    </Container>
                                </Col>
                                </Row>
                        </Container>
                    </Jumbotron>

                </div>
            } />
        )
    }
}
